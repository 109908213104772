import { extendTheme, theme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { ComponentProps } from 'react';
import { Heading } from '@chakra-ui/layout';

import { LightenDarkenColor } from 'utils';

export const commonGradients = {
  primaryDarkToPrimaryDefault: `linear-gradient(180deg, #2B6CB0 0%, #1E59AE 100%);`,
  grayToTransparent: `linear-gradient(180deg, #E8E8E8 0%, rgba(232, 232, 232, 0) 100%);`,
  whiteGrayToTransparent: `linear-gradient(180deg, #F2F2F2 0%, rgba(255, 255, 255, 0) 100%)`,
  transparentToWhiteGray: `linear-gradient(180deg, rgba(242, 242, 242, 0) 21.59%, #F2F2F2 109.23%);`,
  darkGreenToTransparent: ` linear-gradient(180deg, rgba(151, 188, 177, 0.3) 0%, rgba(151, 188, 177, 0) 100%)`
};

// Responsive component variants are not supported yet. The are on the roadmap. So this is a work around to keep constancy. (https://github.com/chakra-ui/chakra-ui/issues/1028)
export const presetComponentProps = {
  h1: {
    as: 'h1',
    fontWeight: { base: 'normal' },
    fontSize: { base: '3xl', lg: '4xl' },
    fontStyle: 'italic',
    lineHeight: { base: '2.25rem', lg: '2.5rem' }
  } as ComponentProps<typeof Heading>,
  h2: {
    as: 'h2',
    mt: '1rem !important',
    mb: '0.5rem !important',
    fontWeight: 'semibold',
    fontSize: { base: 'xl', sm: '2xl', lg: '3xl' }
  } as ComponentProps<typeof Heading>,
  h3: {
    as: 'h3',
    mb: '1rem',
    fontSize: { base: 'md' }
  } as ComponentProps<typeof Heading>,
  h4: {
    as: 'h4',
    fontSize: { base: 'sm' }
  } as ComponentProps<typeof Heading>,
  pageContainer: {
    // Please keep as rems, other places use these values for calculations (Example of other uses can be found in PageBodyInsetBlock)
    marginBottom: { base: '1.25rem', md: '2rem', lg: '2.5rem' },
    paddingX: { base: '1rem', md: '1.5rem', xxl: '0' },
    mx: 'auto'
  }
};

// Browser bases everything off of 16px
export const customBreakpoints = {
  base: '0',
  sm: '30em', // 480px
  md: '48em', // 768px
  lg: '80em', // 1280px
  xl: '81em', // 1296px
  '2xl': '90em' // 1440px
};

const colors = {
  default: '#323233',
  extras: {
    default: '#215DA5',
    ltblue: '#1dbcf2',
    ltblueDark: '#18ade6',
    pink: '#EC8FCE',
    green: '#59C86D',
    greenDark: '#89ba00',
    oliveLight: '#A7E400',
    olive: '#9FD900',
    oliveDark: '#7fad44',
    oliveDarker: '#8fbd32',
    ltGray:'#718096'
  },
  primary: {
    hover: '#0d3561',
    dark: '#104783',
    darker: '#2a4365',
    medium: ' #1E59AE',
    default: '#005dab',
    vibrant: '#239dfb',
    light: '#7fbee7',
    white: '#FFFFFF'
  },
  shades: {
    400: '#666666',
    300: '#979798',
    250: '#c4c4c4',
    200: '#c9c9ca',
    100: '#e1e1e3',
    50: '#f1f1f1'
  },
  status: {
    success: '#34aa44',
    warning: '#eed202',
    alert: '#e73a26'
  },
  accent: {
    default: '#97bcb1',
    ldExtras: '#B6BD01'
  },
  muted: {
    success: '#eaf6ec',
    alert: '#fdf1f0',
    primaryLight: '#f2f9ff'
  }
};

const breakpoints = createBreakpoints(customBreakpoints);

const variantOutlined = () => ({
  field: {
    borderColor: theme.colors.gray[300],
    _focus: {
      borderColor: theme.colors.gray[400]
    }
  }
});

const ldTheme = extendTheme({
  colors,
  breakpoints,
  fonts: {
    body: 'roboto, serif',
    heading: 'roboto, serif',
    mono: 'roboto, serif'
  },
  fontSizes: {
    xs: '0.75rem', // 12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    '1.5xl': '1.375rem', //22px
    '2xl': '1.5rem', // 24px
    '2.5xl': '1.75rem', //28px
    '3xl': '1.875rem', // 30px
    '4xl': '2rem', // 32px
    '5xl': '2.625rem', // 42px
    '6xl': '3.75rem', // Desktop Heading size
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem'
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900
  },
  sizes: {
    ...theme.space,
    7.5: '1.875rem',
    container: {
      ...theme.sizes.container,
      form: '860px',
      standard: '900px',
      xxl: '1300px'
    }
  },

  components: {
    Input: {
      variants: {
        outline: variantOutlined
      }
    },
    Select: {
      variants: {
        outline: variantOutlined
      }
    },
    Textarea: {
      variants: {
        outline: () => variantOutlined().field
      }
    },
    Divider: {
      baseStyle: {
        borderColor: 'shades.300'
      }
    },
    Text: {
      baseStyle: {
        color: 'default'
      },
      variants: {
        disclaimer: {
          fontWeight: 'light',
          fontSize: 'xs',
          color: 'shades.300',
          lineHeight: '4'
        },
        ldHeading: {
          color: 'primary.default',
          fontSize: { base: 'xl', md: '2xl', lg: '3xl' },
          lineHeight: { base: '7', md: '9' }
        }
      }
    },
    Button: {
      variants: {
        ld: {
          bg: 'primary.default',
          color: 'white',
          height: '12',
          paddingX: { base: '16px', lg: '16px' },
          paddingY: { base: '12px', lg: '24px' },
          fontSize: 'sm',
          fontWeight: 'bold',
          _hover: { bg: LightenDarkenColor('#005dab', -10), _disabled: null }
        },
        ldSm: {
          bg: 'white',
          color: 'primary.default',
          border: '2px solid',
          borderColor: 'primary.default',
          borderRadius: '5px',
          height: { base: '40px', lg: '50px' },
          paddingX: { base: '30px', lg: '54px' },
          paddingY: { base: '12px', lg: '17px' },
          fontWeight: 'bold',
          fontSize: { base: 'sm' },
          width: { base: '154px', md: '156px', lg: '204px' }
        },
        ldOutline: {
          bg: 'white',
          color: 'primary.default',
          border: '1px solid',
          borderColor: 'primary.default',
          fontSize: 'lg',
          fontWeight: 'semibold',
          height: '12',
          paddingX: '6',
          _hover: { bg: 'gray.100' }
        },
        ldLoadable: {
          bg: 'extras.greenDark',
          color: 'white',
          paddingX: { base: '16px', lg: '16px' },
          paddingY: { base: '12px', lg: '24px' },
          fontSize: 'sm',
          fontWeight: 'bold',
          cursor: 'pointer',
          _hover: { bg: LightenDarkenColor('#89ba00', -10), _disabled: null }
        },
        ldLoaded: {
          bg: 'white',
          color: 'extras.greenDark',
          border: '2px solid #89ba0077',
          paddingX: { base: '16px', lg: '16px' },
          paddingY: { base: '12px', lg: '24px' },
          fontSize: 'sm',
          fontWeight: 'bold'
        }
      }
    },
    Radio: {
      defaultProps: {
        cursor: 'pointer !important'
      },
      sizes: {
        xl: {
          control: { w: 7, h: 7 },
          label: { fontSize: 'xl' }
        }
      }
    },
    Link: {
      variants: {
        upperDesktopHeaderLeft: {
          paddingX: '4',
          paddingY: '2.5',
          borderLeft: '1px',
          borderColor: colors?.shades?.[50],
          _hover: { bg: 'shades.50', textDecoration: 'none' },
          color: 'primary.dark',
          fontWeight: 'medium'
        }
      }
    },
    Table: {
      variants: {
        ldextras: {
          td: {
            width: '33.33333%',
            padding: { base: '4', md: '6' },
            fontSize: { base: 'sm', md: 'md' }
          },
          tr: {
            _even: {
              background: 'gray.100'
            },
            _odd: {
              background: 'gray.50'
            }
          },
          thead: {
            th: {
              padding: { base: '4', md: '6' }
            }
          },
          tfoot: {
            tr: {
              _odd: {
                background: 'white',
                color: 'gray.400',
                fontSize: 'xs'
              }
            }
          }
        }
      }
    },
    Tabs: {
      variants: {
        'enclosed-colored': {
          tabList: {
            backgroundColor: 'white',
            borderBottom: '1px solid #E2E8F0',
            paddingLeft: '0'
          },
          tab: {
            fontWeight: 'bold',
            bg: 'white',
            fontSize: { base: 'xs', md: 'md' },
            border: '1px solid #CDD8E2',
            _selected: {
              bg: '#F2F9FF',
              borderTop: 'none',
              borderBottom: '3px solid #005DAB'
            }
          }
        }
      }
    }
  }
});

export default ldTheme;
