import '../styles/globals.css';
import '@fontsource/roboto';
import 'swiper/css';
import 'swiper/css/navigation';
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { useIdleTimer } from 'react-idle-timer';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { ChakraProvider } from '@chakra-ui/react';
import TagManager from 'react-gtm-module';
import { ReactElement, useEffect, useRef } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { getCookie, setCookies } from 'cookies-next';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { StoreProvider, createStore } from '../store';
import { DesktopHeader, MobileHeader } from 'components/Header';
import Footer from 'components/Footer';
import ldTheme from '../theme';
import { fetchGlobalAppData, GlobalAppData } from 'api/contentful-fetch';
import { UnknownObjectAny } from 'types/global.types';
import {
  AUTH_TOKEN_COOKIE,
  CUSTOMER_ID_COOKIE,
  NEXT_PREVIEW_COOKIE,
  SKIP_HOLDING_PAGE_COOKIE,
  USER_ID_COOKIE
} from 'utils/constants';
import { PreviewBanner } from 'components/CMS';
import { useRouter } from 'next/router';
import { GTMPageViewEvent, GTMPageViewEventData } from 'utils/gtmHelpers';
import { isClientSide, isInitialSiteLoad } from 'utils';
import { gatherMemberData } from 'api/extras';
import SmartBanner from 'components/SmartBanner';

interface MyAppProps extends AppProps, GlobalAppData {
  isPreview?: boolean;
  profile: UnknownObjectAny;
  userId?: string;
  customerId?: string;
  token?: string;
}

library.add(fas);
library.add(far);

SwiperCore.use([Navigation]);

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

Sentry.init({
  dsn: 'https://b1a1f91bf87e44c9b3138aa8c2957c5e@o262865.ingest.sentry.io/6769670',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2
});
function MyApp({
  Component,
  pageProps,
  globalHeaderBanner,
  mainNavigation,
  footerNavigation,
  isPreview,
  profile,
  userId,
  customerId,
  token
}: MyAppProps): ReactElement {
  const store = useRef(
    createStore({
      cmsStore: { globalHeaderBanner, mainNavigation, footerNavigation },
      extrasStore: { profile, userId, customerId, token }
    })
  ).current;
  const { asPath } = useRouter();

  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_ID ?? '' });
  }, []);

  const pageTitle = asPath
    .split('/')
    ?.[asPath.split('/').length - 1]?.split('-')
    .map((s: string) => s.charAt(0).toUpperCase() + s.slice(1))
    .join('');

  const pageInfo: GTMPageViewEventData = {
    page_url: asPath,
    page_title: pageTitle !== '' ? pageTitle : 'Prescription Refills'
  };

  useEffect(() => {
    let mounted = true;

    if (!mounted) {
      return;
    }

    GTMPageViewEvent(pageInfo);

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  // Removing this idle timer breaks the site for some reason, need to keep it in here and just have it do nothing
  const onIdle = () => null;
  useIdleTimer({ onIdle, timeout: 1000 * 60 * 60 * 24 * 999 });

  const isStoreSignupPage = asPath?.includes('/grand-opening') || asPath?.includes('inStore=true');
  const isComponentPreview = asPath?.includes('contentful/component-preview');
  return (
    <StoreProvider value={store}>
      <ChakraProvider theme={ldTheme} resetCSS>
        <Head>
          <link rel="manifest" href="/manifest.webmanifest" />
          <link href="/icons/icon-16x16.png" rel="icon" type="image/png" sizes="16x16" />
          <link href="/icons/icon-32x32.png" rel="icon" type="image/png" sizes="32x32" />
          <link rel="shortcut icon" type="image/jpg" href="/favicon.ico" />
          <meta name="theme-color" content="#fff" />
          {/* <meta httpEquiv='cache-control' content='no-cache' />
          <meta httpEquiv='expires' content='0' />
          <meta httpEquiv='pragma' content='no-cache' /> */}
          <link rel="apple-touch-icon" href="/icons/icon-192x192.png"></link>
          {pageProps?.content?.pageTitleMeta && <title>{pageProps.content.pageTitleMeta}</title>}
          {pageProps?.content?.pageDescriptionMeta && (
            <meta name="description" content={pageProps.content.pageDescriptionMeta} />
          )}
        </Head>
        <SmartBanner title="London Drugs" author="London Drugs Limited" position="top" />
        {!isComponentPreview && <DesktopHeader simpleHeader={isStoreSignupPage} />}
        {!isComponentPreview && <MobileHeader simpleHeader={isStoreSignupPage} />}
        {isPreview && <PreviewBanner />}
        <Component {...pageProps} />
        {!isComponentPreview && <Footer simpleFooter={isStoreSignupPage} />}
      </ChakraProvider>
    </StoreProvider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const app = await App.getInitialProps(appContext);
  const { ctx } = appContext;
  const { req, res, asPath } = ctx;
  try {
    const letMeInCookie = getCookie(SKIP_HOLDING_PAGE_COOKIE, { req, res });
    if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true' && !letMeInCookie && res) {
      if (asPath === '/letmein') {
        const maxAge = 60 * 60; // 60 minutes
        setCookies(SKIP_HOLDING_PAGE_COOKIE, true, { req, res, maxAge });
        res.writeHead(307, { Location: '/' });
        res.end();
      } else {
        res.writeHead(307, { Location: '/holding.html' });
        res.end();
      }
    }
    let isPreview = false;
    if (getCookie(NEXT_PREVIEW_COOKIE, { req, res })) {
      isPreview = true;
    }
    // only fetch global data once
    if (isInitialSiteLoad(req?.url)) {
      let profile = {};
      const authToken = getCookie(AUTH_TOKEN_COOKIE, { req, res });
      const userId = getCookie(USER_ID_COOKIE, { req, res });
      const customerId = getCookie(CUSTOMER_ID_COOKIE, { req, res });
      if (typeof authToken === 'string' && typeof userId === 'string' && !isClientSide()) {
        profile = await gatherMemberData(authToken, userId);
      }

      const globalAppData = await fetchGlobalAppData();

      return {
        ...app,
        ...globalAppData,
        isPreview,
        profile,
        userId,
        customerId,
        token: authToken
      };
    } else {
      return { ...app, isPreview };
    }
  } catch (error) {
    console.error('ERROR FETCHING MyApp PROPS');
    console.error(error);
    return { ...app };
  }
};

export default MyApp;
