import { CONTENT_TYPES } from 'utils/constants';
import { MEMBER_PANEL_BULLET_ITEM } from './dashboard';

export const IMAGE_FRAGMENT = `
fragment Image on ${CONTENT_TYPES.ASSET} {
  __typename
  description
  width
  height
  url
}
`;

export const FEATURE_SPLIT = `
fragment ${CONTENT_TYPES.FEATURE_SPLIT}Fragment on ${CONTENT_TYPES.FEATURE_SPLIT} {
  __typename
  title
  headline
  headlineColor
  text
  textColor
  buttonText
  buttonColor
  buttonTextLink
  imageLocation
  image {
    ...Image
  }
  videoModal {
    url
  }
} 
`;

export const IMAGE_IN_TEXT = `
fragment ${CONTENT_TYPES.IMAGE_IN_TEXT}Fragment on ${CONTENT_TYPES.IMAGE_IN_TEXT} {
  __typename
  title
  image {
    ...Image
  }
  imageWidth
  caption
  sys {
    id
  }
} 
`;

export const TEXT = `
${IMAGE_IN_TEXT}
fragment ${CONTENT_TYPES.TEXT}Fragment on ${CONTENT_TYPES.TEXT} {
  __typename
  title
  content {
    json
    links {
      entries {
        block {
          ...on ${CONTENT_TYPES.IMAGE_IN_TEXT} {
            ...${CONTENT_TYPES?.IMAGE_IN_TEXT}Fragment
          }
          __typename
        }
      }
    }
  }
}
`;

export const FAQ_ITEM = `
fragment ${CONTENT_TYPES.FAQ_ITEM}Fragment on ${CONTENT_TYPES.FAQ_ITEM} {
  question
  answer
  faqType
  topFaq
}
`;

export const PROGRAM_FEATURE_WITH_ICON = `
fragment ${CONTENT_TYPES.PROGRAM_FEATURE_WITH_ICON}Fragment on ${CONTENT_TYPES.PROGRAM_FEATURE_WITH_ICON} {
  title
  icon {
    ...Image
  }
  iconBackgroundColor
  text
  textColor
  linkUrl
}
`;

export const PROGRAM_FEATURE_WITH_IMAGE = `
fragment ${CONTENT_TYPES.PROGRAM_FEATURE_WITH_IMAGE}Fragment on ${CONTENT_TYPES.PROGRAM_FEATURE_WITH_IMAGE} {
  title
  image {
    ...Image
  }
  imageLocation
  text
  textBackgroundColor
  textColor
  linkUrl
}
`;

export const EXAMPLE_PRODUCT_ITEM = `
fragment ${CONTENT_TYPES.EXAMPLE_PRODUCT_ITEM}Fragment on ${CONTENT_TYPES.EXAMPLE_PRODUCT_ITEM} {
  title
  image {
    ...Image
  }
  pointsAvailable
  headline
  expirationDate
  productCategory
}
`;

export const PROGRAM_BENEFIT_ITEM = `
fragment ${CONTENT_TYPES.PROGRAM_BENEFIT_ITEM}Fragment on ${CONTENT_TYPES.PROGRAM_BENEFIT_ITEM} {
  __typename
  title
  benefitName
  tier1BenefitText
  tier2BenefitText
  tierAssignment
}
`;

export const LIST = `
${PROGRAM_FEATURE_WITH_ICON}
${PROGRAM_FEATURE_WITH_IMAGE}
${EXAMPLE_PRODUCT_ITEM}
${PROGRAM_BENEFIT_ITEM}
${MEMBER_PANEL_BULLET_ITEM}
fragment ${CONTENT_TYPES.LIST}Fragment on ${CONTENT_TYPES.LIST} {
  title
  headline
  headlineColor
  text
  disclaimerSmallText
  itemCollection {
    items {
      __typename
      ... on ${CONTENT_TYPES.PROGRAM_FEATURE_WITH_ICON} {
        ...${CONTENT_TYPES.PROGRAM_FEATURE_WITH_ICON}Fragment
      }
      ... on ${CONTENT_TYPES.PROGRAM_FEATURE_WITH_IMAGE} {
        ...${CONTENT_TYPES.PROGRAM_FEATURE_WITH_IMAGE}Fragment
      }
      ... on ${CONTENT_TYPES.EXAMPLE_PRODUCT_ITEM} {
        ...${CONTENT_TYPES.EXAMPLE_PRODUCT_ITEM}Fragment
      }
      ... on ${CONTENT_TYPES.PROGRAM_BENEFIT_ITEM} {
        ...${CONTENT_TYPES.PROGRAM_BENEFIT_ITEM}Fragment
      }
      ...on ${CONTENT_TYPES.FAQ_ITEM} {
        ...${CONTENT_TYPES.FAQ_ITEM}Fragment
      }
      ...on ${CONTENT_TYPES.LOGGED_IN_HOME_BANNER} {
        ...${CONTENT_TYPES.LOGGED_IN_HOME_BANNER}Fragment  
      }
      ...on ${CONTENT_TYPES.MEMBER_PANEL_BULLET_ITEM} {
        ...${CONTENT_TYPES.MEMBER_PANEL_BULLET_ITEM}Fragment
      }
    }
  }
}
`;

export const BUTTON_GROUP_LIST = `
fragment ${CONTENT_TYPES.BUTTON_GROUP_LIST}Fragment on ${CONTENT_TYPES.BUTTON_GROUP_LIST} {
  title
  buttonsCollection {
    items {
      buttonText
      buttonTextLink
      buttonColor
    }
  }
}
`;
