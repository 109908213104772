import shajs from 'sha.js';

export const sha256 = (pin: string) => {
  return shajs('sha256').update(pin).digest('hex').toUpperCase();
};

export const serialize = (data: any) => {
  const uriComponents: any[] = [];
  Object.keys(data).forEach((key) =>
    uriComponents.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
  );
  return uriComponents.join('&');
};

const oneDay = 1000 * 60 * 60 * 24; // 24 hrs in milliseconds

export const getFirstDayOfMonth = (date: Date): string => {
  // actually getting the last day of the previous month because rewards and transactions sometimes don't get logged the same day
  return new Date(+new Date(date.getFullYear(), date.getMonth(), 1) - oneDay)
    .toISOString()
    .replace('Z', '-0000');
};

export const getLastDayOfMonth = (date: Date): string => {
  // actually getting the first day of the next month because rewards and transactions sometimes don't get logged the same day
  return new Date(+new Date(date.getFullYear(), date.getMonth() + 1, 0) + oneDay)
    .toISOString()
    .replace('Z', '-0000');
};

export const getThreeMonthsAgo = (date: Date): string => {
  // initialize the date off the ending month
  const threeMonthsAgo = new Date(date);
  // set the date to three months prior
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
  return threeMonthsAgo.toISOString().replace('Z', '-0000');
};

export const convertDateToPST = (dateString: string): Date => {
  if (!dateString) {
    return new Date();
  }
  const utcDate = new Date(dateString);
  // Kobie's database is UTC, get the date based on midnight PST
  return new Date(
    `${utcDate.getUTCMonth() + 1}/${utcDate.getUTCDate()}/${utcDate.getUTCFullYear()} PST`
  );
};

export const convertOfferExpirationDate = (
  expirationDateString: string,
  dateOffset = 2,
  setToMidnight = false
): Date => {
  if (!expirationDateString) {
    return new Date();
  }
  const apiExpirationDate = convertDateToPST(expirationDateString);
  const userExpirationDateMidnight = new Date(
    apiExpirationDate.setDate(apiExpirationDate.getDate() - dateOffset)
  );
  if (setToMidnight) {
    return userExpirationDateMidnight;
  }
  return new Date(userExpirationDateMidnight.setHours(23, 59, 59, 999));
};
