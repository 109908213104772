import React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';
import { presetComponentProps } from 'theme';
import { PageQueryRes } from 'types/cms/queries';
import HeroCard from 'components/MyExtras/HeroCard';

export interface PageHeroProps
  extends Pick<
    PageQueryRes,
    'headerBackgroundColor' | 'headerIconPattern' | 'headerImage' | 'headlineH1' | 'headlineH1Part2'
  > {
  useSlimHero?: boolean;
  isExtrasDashboard?: boolean;
}

const PageHero = ({
  headerBackgroundColor,
  headerIconPattern,
  headerImage,
  headlineH1,
  headlineH1Part2,
  useSlimHero,
  isExtrasDashboard
}: PageHeroProps): JSX.Element => {
  // Used to determine the line under the hero. When bg color is extras green, always use ld blue
  const heroDividerColor = headerBackgroundColor === '#9FD900' ? 'primary.default' : 'extras.olive';

  const height = isExtrasDashboard
    ? 'initial'
    : {
        base: useSlimHero ? '10.625rem' : '10.625rem',
        sm: useSlimHero ? '13.75rem' : '13.75rem',
        md: useSlimHero ? '11.0625rem' : '16.875rem'
      };

  return (
    <>
      <Box
        h={height}
        w="100%"
        bgColor={headerBackgroundColor}
        backgroundImage={`url(${headerIconPattern?.url})`}
        backgroundSize="108px"
      >
        {isExtrasDashboard ? (
          <Flex
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            paddingX="5"
            paddingY={{ base: '6', md: '66px' }}
          >
            <HeroCard />
          </Flex>
        ) : (
          <Box 
            h="100%"
            d="flex" 
            flexDir="row" 
            justifyContent="space-between" 
            maxW="container.xxl" 
            m="auto"
            px={{ base: 0, xl: "1rem" }}
          >
            {/* Used for alignment on desktop */}
            <Box w="10%" d={{ base: 'none', '2xl': 'block' }} />
            <Box
              d="flex"
              justifyContent="center"
              alignItems="center"
              w={{ base: '100%', md: '62%', '2xl': '50%' }}
            >
              <Heading
                {...presetComponentProps.h1}
                color="primary.default"
                d="flex"
                flexDir={{ base: 'column', lg: 'row' }}
                alignItems="center"
              >
                <Box as="span">{headlineH1}</Box>
                {headlineH1Part2 && (
                  <Box as="span" ml={{ base: '0', lg: '2' }} color="white">
                    {headlineH1Part2}
                  </Box>
                )}
              </Heading>
            </Box>
            <Box
              backgroundRepeat="no-repeat"
              backgroundPosition="right"
              backgroundSize="cover"
              backgroundImage={`url(${headerImage?.url})`}
              clipPath="polygon(12% 0%, 100% 0%, 100% 100%, 0% 100%)"
              w="40%"
              role={headerImage?.description ? 'img' : undefined}
              aria-label={headerImage?.description}
              d={{ base: 'none', md: 'block' }}
            />
          </Box>
        )}
      </Box>
      <Box mt="0.25rem" h={{ base: '0.25rem', sm: '0.5rem' }} bgColor={heroDividerColor} />
    </>
  );
};

export default PageHero;
